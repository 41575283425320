import React from "react";

import { withLDProvider } from "launchdarkly-react-client-sdk";
import ConfigHelper from "@/_lib/data/ConfigHelper";
import { LDUserContextConfig } from "@/_lib/utils/launchDarklyHelper";

const launchDarklyKey = ConfigHelper.launch_darkly_client_key ? ConfigHelper.launch_darkly_client_key : "";

function withLDProviderUserInjection(context: LDUserContextConfig | undefined) {
  return function wrapWithLDProvider(Wrapped: React.ComponentType): React.ComponentType {
    return withLDProvider({
      clientSideID: launchDarklyKey,
      context,
    })(Wrapped);
  };
}

export default withLDProviderUserInjection;
