import { cleanTemplateAttributes, Template, TemplateAttributes } from "@/viewer/types/domain/template";

export interface DepartmentAttributes {
  available: boolean;
  compact_name: string;
  department_id: number;
  desc: string;
  filtered: boolean;
  is_hidden: boolean;
  name: string;
  medical_specialty: string;
  templates: TemplateAttributes[];
}

export interface Department {
  id: number | "";
  name: string;
  compactName: string;
  description: string;
  available: boolean;
  filtered: boolean;
  isHidden: boolean;
  medicalSpecialty: string;
  templates: Template[];
}

export const cleanDepartmentAttributes = (attrs: Partial<DepartmentAttributes>): Department => {
  return {
    id: attrs.department_id ?? "",
    name: attrs.name ?? "",
    medicalSpecialty: attrs.medical_specialty ?? "",
    compactName: attrs.compact_name ?? "",
    description: attrs.desc ?? "",
    available: attrs.available ?? false,
    filtered: attrs.filtered ?? false,
    isHidden: attrs.is_hidden ?? false,
    templates: attrs.templates?.map((templateAttrs) => cleanTemplateAttributes(templateAttrs)) ?? [],
  };
};

export type DepartmentsById = Record<string, Department>;
