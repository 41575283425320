"use strict";
/* eslint-disable react/no-string-refs */
require("./_styles.scss");

const React = require("react/addons");
const ApplicationElement = require("./ApplicationElement.jsx");
const LbsBulletin = require("./LbsBulletin.jsx");
const LegacyLink = require("./LegacyLink.jsx");
const LegacyToken = require("_lib/data/models/LegacyToken.js");
const SupportLink = require("./SupportLink.jsx");
const Subscription = require("login/ui/modules/mocal/Subscription.jsx");
const Feed = require("login/ui/modules/feed/Feed.jsx");
const UpcomingShifts = require("./UpcomingShifts.jsx");
const { getBrowserTimezone, isTZAwarenessEnabled } = require("@/viewer/utils/timezones");
const { LDFlagEnums } = require("@/_lib/constants/LDFlagEnums");

// eslint-disable-next-line react/no-deprecated
const SelectionScreen = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },

  componentDidMount: function () {
    window.LbsAppData.Helpers.Analytics.sendPageview(
      "/" + window.LbsAppData.User.attributes.customer_id + "/" + window.LbsAppData.User.attributes.emp_id,
      "Dashboard"
    );
  },

  /* privates */
  _redirect: function (path) {
    // we basically bounce off the backend to get the url destinations...that way we only have to manage
    // those in one spot on the api servers
    let query = "";
    // if a token was passed in...its myclient (an lbs user) spoofing as someone else, we need to pass along their token
    if (this.props.token !== undefined) {
      query = "?token=" + this.props.token;
    }

    //window.top.location = window.LbsAppData.Destinations.get(type) + query;
    // since the 'type' is in the path and not the subdomain, I don't believe we need to get these
    // programmatically anymore -- leaving the above artifact in case I'm incorrect
    window.top.location = "/" + path + query;
  },
  _goToVM: function () {
    // 2 steps...need to create a Unity token, then redirect to the Unity url with that
    const legacyToken = new LegacyToken();
    const useNewVMLinks = this.props?.flags[LDFlagEnums.Lv10089NewVMLinks];

    legacyToken.fetch({
      success: function () {
        if (useNewVMLinks) {
          // redirect homie
          window.top.location = window.LbsAppData.Destinations.get("unity") + "?source=access&dest=ViewManager.aspx";
        } else {
          window.top.location =
            window.LbsAppData.Destinations.get("unity") + "?source=access&dest=ViewsManagement.aspx";
        }
      },
    });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    // eslint-disable-next-line react/no-deprecated
    const classes = React.addons.classSet({
      SelectionScreen: true,
    });

    let applications = [];
    let nothingVisible = false;

    if (window.LbsAppData.AccessibleApps.attributes.viewer) {
      let path = "viewer/";
      const tzEnabled = isTZAwarenessEnabled();
      const tz = getBrowserTimezone();

      const url_string = window.location.href;
      const url = new URL(url_string);
      const existingTz = url.searchParams.get("tz");

      if (!existingTz && tzEnabled && tz) {
        path = `${path}?tz=${tz}`;
      }
      const viewer = (
        <ApplicationElement
          key="viewer"
          type="viewer"
          path={path}
          title="Viewer"
          desc="See when and where you and your co-workers are scheduled."
          redirect={this._redirect}
          ref="ViewerRef"
        ></ApplicationElement>
      );
      applications.push(viewer);
    } else {
      // if viewer isn't visible...it means they are an S1 (old client that needs to be upgraded to the newer S2 build)
      nothingVisible = true;
    }

    if (window.LbsAppData.AccessibleApps.attributes.maker) {
      const maker = (
        <ApplicationElement
          key="maker"
          type="maker"
          path="editor/"
          title="Editor"
          desc="Update your group's schedules."
          redirect={this._redirect}
          ref="MakerRef"
        ></ApplicationElement>
      );
      applications.push(maker);
    }

    if (window.LbsAppData.AccessibleApps.attributes.reports) {
      const reports = (
        <ApplicationElement
          key="reports"
          type="reports"
          path="reports/"
          title="Reports"
          desc="See tallies and statistics for you and your group."
          redirect={this._redirect}
          ref="ReportsRef"
        ></ApplicationElement>
      );
      applications.push(reports);
    }

    if (window.LbsAppData.AccessibleApps.attributes.view_design) {
      const viewDesign = (
        <ApplicationElement
          key="view_design"
          type="view_design"
          path="manager/"
          title="View Manager"
          desc="Centrally create and design your group's views for use in Viewer/Editor."
          redirect={this._goToVM}
          newFlag={false}
          ref="ViewDesignRef"
        ></ApplicationElement>
      );
      applications.push(viewDesign);
    }

    if (nothingVisible) {
      applications = (
        <div className="s1-message">
          Your organization is currently using an older version of our software. Please talk to an LBS representive
          about upgrading to the latest software version so you can begin taking advantage of these new features.
        </div>
      );
    }

    let upcomingShiftsWidget, subscriptionWidget;

    if (window.LbsAppData.MyPersonnel.get("scheduled")) {
      upcomingShiftsWidget = <UpcomingShifts />;
      subscriptionWidget = <Subscription />;
    }

    let center, right;
    if (window.LbsAppData.Helpers.Features.isAvailable("swops")) {
      center = (
        <div id="CenterPad">
          <div className="title">
            <i className="fa fa-fw fa-rss" />
            Swaportunity Feed
          </div>
          <div className="feed-container">
            <Feed />
          </div>
        </div>
      );

      right = (
        <div id="RightPad">
          {subscriptionWidget}
          <LbsBulletin />
          <LegacyLink />
          <SupportLink />
        </div>
      );
    } else {
      center = (
        <div id="CenterPad">
          <LbsBulletin />
        </div>
      );
      right = (
        <div id="RightPad">
          {subscriptionWidget}
          <LegacyLink />
          <SupportLink />
        </div>
      );
    }

    return (
      <div id="SelectionScreen" className={classes}>
        <div id="LeftPad">
          {upcomingShiftsWidget}

          <div className="apps">
            <div className="title">
              <i className="fa fa-fw fa-th" />
              Applications
            </div>
            {applications}
          </div>
        </div>

        {center}

        {right}
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = SelectionScreen;
