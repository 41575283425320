import { LDFlagEnums } from "@/_lib/constants/LDFlagEnums";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import classnames from "@/_lib/utils/classnames";

import ConfigHelper from "@/_lib/data/ConfigHelper";
import { Type } from "@/_lib/ui/modules/topbar/logomenu/LogoMenu";
import { AppName, SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { getBrowserTimezone, isTZAwarenessEnabled } from "@/viewer/utils/timezones";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const docCookies = require("_lib/utils/cookies.js");

interface IProps {
  type: Type;
  appName: AppName;
  settings: SettingsContext;
  isScheduled: boolean;
}

const LogoMenuItem = ({ type, appName, settings, isScheduled }: IProps): React.ReactElement | null => {
  const { view, displayedScheduleId } = settings;
  const tzEnabled = isTZAwarenessEnabled();
  const tz = getBrowserTimezone();

  const LDFlags = useFlags();

  const classes = classnames({
    LogoMenuItem: true,
  });

  const goToAdministration = (): void => {
    window.open(`${ConfigHelper.host_unity}?source=access&dest=Dashboard.aspx`);
  };

  const goToAnalytics = (): void => {
    const unityToken = docCookies.getItem("LB_TKN_UNITY");
    window.open(`${ConfigHelper.host_my_change_logs}/analytics?token=${unityToken}`);
  };

  const goToViewManager = (id?: string): void => {
    if (LDFlags[LDFlagEnums.Lv10089NewVMLinks]) {
      window.open(
        id
          ? `${ConfigHelper.host_unity}?source=access&dest=ViewManager.aspx&route=edit/${id}`
          : `${ConfigHelper.host_unity}?source=access&dest=ViewManager.aspx`
      );
    } else {
      window.open(
        id
          ? `${ConfigHelper.host_unity}?source=access&dest=ViewsManagement.aspx&route=edit/${id}`
          : `${ConfigHelper.host_unity}?source=access&dest=ViewsManagement.aspx`
      );
    }
  };

  const getViewerLink = (basePath: string) => {
    if (tzEnabled && tz) {
      return `${basePath}?tz=${tz}`;
    }
    return basePath;
  };

  const renderTitle = (): React.ReactNode => {
    switch (type) {
      case Type.Dashboard:
        return (
          <a className="title" href="/dashboard/">
            Dashboard
          </a>
        );
      case Type.Viewer:
        return (
          <a className="title" href={getViewerLink("/viewer/")}>
            Viewer
          </a>
        );
      case Type.Editor:
        return (
          <a className="title" href="/editor/">
            Editor
          </a>
        );
      case Type.Reports:
        return (
          <a className="title" href="/reports/">
            Reports
          </a>
        );
      case Type.Administration:
        return (
          <div onClick={() => goToAdministration()}>
            <a className="title">Administration</a>
          </div>
        );
      case Type.Analytics:
        return (
          ConfigHelper.host_my_change_logs && (
            <div onClick={() => goToAnalytics()}>
              <a className="title">Analytics</a>
            </div>
          )
        );
      case Type.ViewManager:
        return (
          <div onClick={() => goToViewManager()}>
            <a className="title">View Manager</a>
          </div>
        );
      default:
        return null;
    }
  };

  const renderItems = (): React.ReactElement[] => {
    const items: React.ReactElement[] = [];

    switch (type) {
      case Type.Viewer:
        if (isScheduled) {
          items.push(
            <a key="me" className="item" href={getViewerLink("/viewer/me")}>
              Me
            </a>
          );
        }
        items.push(
          <a key="today" className="item" href={getViewerLink("/viewer/today")}>
            Today
          </a>
        );
        items.push(
          <a key="default" className="item" href={getViewerLink("/viewer/")}>
            Default
          </a>
        );
        break;
      case Type.Reports:
        if (appName == "editor") {
          items.push(
            <a key="new" className="item" href="/reports/">
              New
            </a>
          );
          items.push(
            <a
              key="shadow"
              className="item"
              target="_blank"
              rel="noreferrer"
              href={"/reports/" + ["#s", displayedScheduleId].join("/")}
            >
              Shadow Current
            </a>
          );
        }
        break;
      case Type.ViewManager:
        if (view) {
          // dont like to things that were converted, cause we don't allow those to be edited through the ui anyways
          // annndd viewer and editor save their views slightly different...which sucks here

          const created_v = view.created_by;
          const id_v = view.view_id ? view.view_id : view.id;

          // dont do this for me/today views
          if (id_v !== "me" && id_v !== "today") {
            if (created_v != "lbapi") {
              items.push(
                <div key="edit_v" onClick={() => goToViewManager(id_v)}>
                  <a className="item">Edit View</a>
                </div>
              );
            }
          }
        }
        break;
      default:
        break;
    }

    return items;
  };

  const title = renderTitle();

  return !title ? null : (
    <div className={classes}>
      <span className="title">{title}</span>
      <div className="content">
        <div className="items">{renderItems()}</div>
      </div>
    </div>
  );
};

export default LogoMenuItem;
